import { Container, Row, Col, Button, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Login.comp.scss";

export const LoginForm = ({
  handleOnChange,
  handleOnSubmit,
  formSwitcher,
  email,
  pass,
  agree,
}) => {
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  return (
    <div className="forms">
      {/* <div>
        <button onClick={() => formSwitcher("signup")} className="no_account">
          Create a free account
        </button>
      </div> */}
      <div className="input-form">
        <input
          type="email"
          name="email"
          onChange={handleOnChange}
          value={email}
          placeholder="Email"
        />

        <div className="input-password">
          <input
            type={passwordType}
            name="password"
            onChange={handleOnChange}
            value={pass}
            placeholder="Password"
          />

          {/* <a
            style={{ position: "absolute", top: "35%", right: "10px" }}
            onClick={togglePassword}
          >
            {" "}
            {passwordType === "password" ? (
              <i className="bi bi-eye-slash"></i>
            ) : (
              <i className="bi bi-eye"></i>
            )}
          </a> */}
        </div>

        <button className="login-button" onClick={handleOnSubmit}>Enter Your AI War Room</button>
      </div>

      {/* <div className="d-flex align-items-center">
        <a
          onClick={() => formSwitcher("reset")}
          style={{
            cursor: "pointer",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          Forgot password?
        </a>
      </div> */}

      <div className="d-flex align-items-center text-center">
        <a onClick={() => formSwitcher("signup")} className="switch2signup">
          Don't have an account?
        </a>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
  formSwitcher: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  pass: PropTypes.string.isRequired,
};
